.article-item{
    padding: 20px;
    border-bottom: 1px solid #EEE;
    padding-bottom: 19px;
    color: #666;
    transition: ease .3s;
}

.article-item:hover{
    background: rgb(247,220,220);
    color: #333;
}

.article-item:last-child{
    border:0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector
{
    background-color: white;
    color: black;
    
    border: 1px solid #eee;
}


.ant-input[disabled]{
    background-color: white;
    color: black;
    border: 1px solid #eee;
  
}
.ant-form-item-control {
    background-color: white;
    color: black;
    border: 1px solid #eee;
}


.ant-btn[disabled]{
    background-color: white;
    color: black;
    border: 1px solid #eee;
}
.ant-input-number-disabled {
    background-color: white;
    color: black;
    border: 1px solid #eee;
}
.ant-picker-input{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}
.ant-picker-input > input[disabled]{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}
.ant-picker.ant-picker-disabled{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}
.ant-checkbox-disabled + span{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}
.ant-checkbox-input{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}
.ant-checkbox .ant-checkbox-checked .ant-checkbox-disabled
{
    background-color: white;
    color: black;
    border: 0px solid #eee;
}