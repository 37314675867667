.content-app{ 
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
}

.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.no-margin{
    margin: 0 !important;
}


  @media print {
    @page {
      size: A4 portrait;
      margin: 1.0cm;
    }
    body {
      font-size: 12pt;
      font-family: serif;
      color: black;
    }
    .header {
      display: none;
    }
  }
